/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

            // JavaScript to be fired on all pages

            /*$('.mobile-menu-trigger').click(function() {
              $(this).toggleClass('active');
              $(this).children('i').toggleClass('icon-menu').toggleClass('icon-cancel');
              $('body').toggleClass('mobile-menu-open');
            });

            $('.mobile-search-trigger, .parent').click(function(e) {
              e.preventDefault();
              $(this).toggleClass('active');
              $(this).children('.mobile-search-wrapper, .sub-menu').toggleClass('visible');
            });*/

            var didScroll;
            var lastScrollTop = 0;
            var delta = 10;
            var navbarHeight = $('.main-header').outerHeight();
            var treshold = 0;

            function hasScrolled() {
              var st = $(window).scrollTop();

              // Make sure they scroll more than delta
              if (Math.abs(lastScrollTop - st) <= delta)
                return;
              if (st <= treshold) {
                $('.main-header').removeClass('nav-down');
              } else {

                // Scroll Up

                if (st + $(window).height() < $(document).height()) {
                  $('.main-header').addClass('nav-down');
                }
              }

              lastScrollTop = st;
            }

            // on scroll, let the interval function know the user has scrolled

            $(window).scroll(function() {
              didScroll = true;
            });

            // run hasScrolled() and reset didScroll status

            setInterval(function() {
              if (didScroll) {
                hasScrolled();
                didScroll = false;
              }
            }, 250);

            // modal windows

            var modal = $('.modal');
            var trigger = $('.service-trigger');
            var closeButton = $('.close-button');

            function toggleModal() {
              modal.toggleClass('show-modal');
              $('body').toggleClass('modal-visible');
            }

            function windowOnClick(event) {
              var target = $(event.target);
              if (target.is(modal)) {
                toggleModal();
              }
            }

            trigger.on('click', toggleModal);
            closeButton.on('click', toggleModal);
            $(window).on('click', windowOnClick);

            // request service popup custom file upload
            $('#service-images').attr('multiple', 'true');
            $('#service-images').on('change', function(e) {
              input = $(this);
              label = $('.service-images-label');
              /*var ext = input.value().split(".");
              ext = ext[ext.length-1].toLowerCase();
              var arrayExtensions = ["jpg" , "jpeg", "png"];

              if (arrayExtensions.lastIndexOf(ext) == -1) {
                console.log("Wrong extension type.");
                //$("#image").val("");
              }*/

              //console.log(event.target.files);

              if (!event.target.files[0]) {
                return;
              }
              else if(e.target.files.length > 1){
                label.html(e.target.files.length + ' files selected');
              }else{
                label.html(e.target.files[0].name + ' selected');
              };


              /*
              if($input.files && input.files.length > 1)
                fileName = ($input.getAttribute('data-multiple-caption') || '').replace('{count}', $input.files.length);
              else if(e.target.value)
                fileName = e.target.value.split('\\').pop();
              if(fileName)
                label.find('span').html(fileName);
              else
                label.html( labelVal );*/
            });

            // Mobile Navigation

            $('.mobile-nav-trigger').click(function() {
              $('body').toggleClass('mobile-menu-open');
              $(this).toggleClass('active');
              $('.mobile-nav-wrapper').toggleClass('active');
            });

            if ($(window).width() <= 1023) {
              $(window).resize(function() {
                $('body').removeClass('mobile-menu-open');
                $('.mobile-nav-trigger').removeClass('active');
                $('.mobile-nav-wrapper').removeClass('active');
              });
            }

            // footer column accordion

            var canclickquestion = true;
            $('.accordion-trigger').on('click', function() {
              if ($(window).width() <= 1023) {
                if (canclickquestion) {
                  canclickquestion = false;
                  $(this).toggleClass('active').next('.accordion-content').slideToggle(300, function() {
                    // Animation complete.
                    canclickquestion = true;
                  });
                }
              };
            });

          },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Our Work
    'our_work': {
      init: function() {
        // JavaScript to be fired on the our work page

        // modal galleries
        var gallerymodal = $('.gallery-modal');
        var projectlist = $('.project-list');
        var controlstarget;

        function openGalleryModal() {
          controlstarget = $(this).attr('data-target');
          $(controlstarget).addClass('show-modal');
          $('body').addClass('gallery-visible');
          $(controlstarget).find('.gallery-carousel').slick({
            arrows: false,
            dots: true,
            //useTransform: false,
            //variableWidth: true,
            slidesToShow: 1,
            rows: 0,
            //slide: 'gallery-item',
            lazyLoad: 'ondemand',
            centerPadding: 0,
            slidesToScroll: 1,
            centerMode: true
          });
        }

        function closeGalleryModal() {
          $(controlstarget).removeClass('show-modal');
          $('body').removeClass('gallery-visible');
          setTimeout(function() {
            //$(controlstarget).find('.gallery-carousel').slick('destroy');
          }, 500);
        }

        projectlist.on('click', '.gallery-trigger', openGalleryModal);
        projectlist.on('click', '.gallery-close', closeGalleryModal);

        projectlist.on('click', '.slider-left', function() {
          $(controlstarget).find('.gallery-carousel').slick('slickPrev');
        });

        projectlist.on('click', '.slider-right', function() {
          $(controlstarget).find('.gallery-carousel').slick('slickNext');
        });

        //load more projects

        var loadbutton = $('.loadmore-button');

        function loadProjects(){
          var data = {
            'action': 'loadmore',
      			'query': projects, // that's how we get params from wp_localize_script() function
      			'page' : current_page
      		};

          $.ajax({
      			url : ajaxurl, // AJAX handler
      			data : data,
      			type : 'POST',
      			beforeSend : function ( xhr ) {
      				loadbutton.text('Loading...'); // change the button text, you can also add a preloader image
      			},
      			success : function( data ){
      				if( data ) {
      					loadbutton.html( '<i class="icon-plus"></i>Load More Projects' ).prev().before(data); // insert new posts

      					current_page++;

      					if ( current_page == max_page )
      						loadbutton.remove(); // if last page, remove the button

      					// you can also fire the "post-load" event here if you use a plugin that requires it
      					// $( document.body ).trigger( 'post-load' );
      				} else {
      					loadbutton.remove(); // if no data, remove the button as well
      				}
      			}
      		});
        }

        loadbutton.click(function() {
      		loadProjects();
      	});
/*
        if ($(window).width() > 1023) {
          var numberofprojects = 7;
        } else {
          var numberofprojects = 3;
        }

        for (var i = 1; i < numberofprojects; i++) {
          setTimeout( function timer(){
              loadProjects();
          }, i*300 );
        }*/

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {

      }
    },
    'services': {
      init: function() {
        // footer column accordion

        var canclicktitle = true;
        $('.service-block h3').on('click', function() {
          if ($(window).width() <= 1023) {
            if (canclicktitle) {
              canclicktitle = false;
              $(this).toggleClass('active').next('p').toggleClass('active').slideToggle(300, function() {
                // Animation complete.
                canclicktitle = true;
              });
            }
          }
        });

        $(window).resize(function() {
          $('p:hidden').css('display', '');
          $('p.active').removeClass('active').slideUp('fast');
          $('h3.active').removeClass('active');
        });
      },
      finalize: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
